import { useCurrentUserId } from "shared/auth-hooks";
import {
  fetchUISettings,
  UISettingsTableEntity,
  useUISettings,
  useUpdateUISettings,
} from "shared/ui-settings/useUISettings";

export enum ThemeOption {
  Light = "light",
  Dark = "dark",
  System = "system",
}

export type UserPreferences = {
  theme: ThemeOption;
  dynamicBackfills: boolean;
};

export function useUserPreferences() {
  const userId = useCurrentUserId();

  const uiSettings = useUISettings<UserPreferences>({
    entityId: UISettingsTableEntity.USER_PREFERENCES,
    userId,
  });

  return uiSettings;
}

export function useUpdateUserPreferneces() {
  const userId = useCurrentUserId();

  return useUpdateUISettings({
    entityId: UISettingsTableEntity.USER_PREFERENCES,
    userId: userId,
  });
}

export function fetchUserPreferences({ userId }: { userId: string }) {
  return fetchUISettings({
    userId,
    entityId: UISettingsTableEntity.USER_PREFERENCES,
  });
}
